import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import FirebaseService from "../../services/FirebaseService";
import { useNotify, Notification } from "react-admin";

const apiUrl = "https://api-gcp.divicars.ai";
// const apiUrl = "http://localhost:8080";
const measurement_id = `G-4EQKT152GT`;
const api_secret = `ncOclLD2Suqy9Prc5WWXqg`;

interface EditComponentProps {
  selected: any;
  setOpenMore: (open: boolean) => void;
  callbackLoadAll: () => void;
}

interface InventoryMake {
  id: number;
  make: string;
  models: string[];
}

interface FormData {
  id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  make: string;
  model: string;
  year: string;
  insurance_offers: boolean;
  auto_loan_offers: boolean;
  isUsed: boolean;
  dealer_id: number;
  vehicle_id: number;
}

const EditComponent: React.FC<EditComponentProps> = ({
  selected,
  callbackLoadAll,
  setOpenMore,
}) => {
  const { register, handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: selected,
  });

  const [inventoryMakes, setInventoryMakes] = useState<InventoryMake[]>([]);
  const [availableModels, setAvailableModels] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [avaliableSubmit, setAvaliableSubmit] = useState<boolean>(
    selected.type === "success" ? false : true
  );
  const [dealers, setDealers] = useState<any[]>([]);
  const notify = useNotify();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    const response = await FirebaseService.updateLeadForm2(data);
    if (response !== null) {
      callbackLoadAll();
    } else {
      console.log("fail");
    }
    setTimeout(() => {
      setLoading(false);
      setOpenMore(false);
    }, 1000);
  };

  const handleSubmitAutoWeb = async () => {
    const formDAta = {
      ...getValues(),
      year: getValues().year,
      make: getValues().make,
      model: getValues().model,
      first_name: getValues().full_name.split(" ")[0] || "",
      last_name: getValues().full_name.split(" ")[1] || "",
      vehicle_id: 433419481,
      dealerId: getValues().dealer_id,
    };

    setLoading(true);
    const autoWebResponse = await sendToAutoWeb(formDAta);
    const {
      accepted,
      errors: { error },
    } = autoWebResponse.post_result;

    const data = {
      ...getValues(),
      type: accepted ? "success" : "fail",
      error: (!accepted && error[0].message) || "",
    };

    if (data.type === "success") {
      handleSendGaEvent({ email: data.email });
    } else {
      notify(error[0].message, {
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 10000,
      });
      setLoading(false);
      return;
    }
    onSubmit(data);
  };

  const handleMakeChange = (event: any) => {
    const selectedMake = event.target.value;
    const selectedMakeData = inventoryMakes.find(
      (make) => make.make === selectedMake
    );
    if (selectedMakeData) {
      console.log(
        " setAvailableModels(selectedMakeData.models);",
        selectedMakeData.models
      );
      setAvailableModels(selectedMakeData.models);
    }
  };

  const sendToAutoWeb = async (item: any) => {
    if (!item.isUsed && item.vehicle_id) {
      delete item.vehicle_id;
      item = { ...item, dealers: dealers };
    }
    try {
      const response = await fetch(`${apiUrl}/api/generateLead`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to generate lead:", error);
      return null;
    }
  };

  const handleSendGaEvent = (data: any) => {
    fetch(
      `https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`,
      {
        method: "POST",
        body: JSON.stringify({
          client_id: "divicars",
          events: [
            {
              name: "lead_form_2_submit",
              params: data,
            },
          ],
        }),
      }
    );
  };

  const fetchInventoryMakes = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/inventory-makes`);
      if (response.status !== 200) {
        console.error("Failed to fetch inventory makes");
        return;
      }
      const data: InventoryMake[] = await response.json();
      setInventoryMakes(data);

      const selectedMakeData = data.find((make) => make.make === selected.make);

      selectedMakeData && setAvailableModels(selectedMakeData.models);
    } catch (error) {
      console.error("Failed to fetch inventory makes:", error);
    }
  };

  const fetchDealer = async () => {
    const { year, make, model, zip_code, isUsed } = getValues();
    if (isUsed || selected.type === "success") {
      if (isUsed && selected.type === "fail") {
        setAvaliableSubmit(true);
      }
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/dealers?year=${year}&make=${make}&model=${model}&zipCode=${zip_code}`
      );
      if (response.status !== 200) {
        notify("Year, Make, and Model combination is invalid.", {
          anchorOrigin: { vertical: "top", horizontal: "center" },
          type: "error",
          autoHideDuration: 10000,
        });
        setAvaliableSubmit(false);
        return;
      }
      const data = await response.json();
      console.log("data", data);
      setValue("dealer_id", data[0].dealer_id);
      setDealers(data);
      setAvaliableSubmit(true);
    } catch (error) {
      console.error("Failed to fact dealer", error);
    }
  };

  useEffect(() => {
    fetchInventoryMakes();
    fetchDealer();
  }, [selected]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Notification />
      {loading && <LinearProgress sx={{ height: "8px" }} />}
      <DialogContent>
        <Box
          sx={{ marginBottom: 5 }}
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            width="80%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ color: "red" }} gutterBottom>
              {selected.error || ""}
            </Typography>

            {selected.type === "success" ? (
              <CheckCircleIcon color="success" />
            ) : (
              <ErrorIcon color="error" />
            )}
          </Box>
        </Box>
        <Box display="flex" gap={1}>
          <Box width="40%">
            <Typography variant="subtitle1" gutterBottom>
              Name
            </Typography>
            <TextField
              fullWidth
              {...register("full_name")}
              defaultValue={
                selected.full_name ||
                selected.first_name + selected.last_name ||
                ""
              }
            />
          </Box>
          <Box width="40%">
            <Typography variant="subtitle1" gutterBottom>
              Email
            </Typography>
            <TextField
              fullWidth
              {...register("email")}
              defaultValue={selected.email || ""}
            />
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Phone
            </Typography>
            <TextField
              {...register("phone")}
              defaultValue={selected.phone || ""}
            />
          </Box>
        </Box>
        <Divider style={{ margin: "15px 0" }} />
        <Box display="flex" gap={1}>
          <Box width="40%">
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <TextField
              fullWidth
              {...register("address")}
              defaultValue={selected.address || ""}
            />
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              City
            </Typography>
            <TextField
              {...register("city")}
              defaultValue={selected.city || ""}
            />
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              State
            </Typography>
            <TextField
              {...register("state")}
              defaultValue={selected.state || ""}
            />
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              ZipCode
            </Typography>
            <TextField
              {...register("zip_code")}
              defaultValue={selected.zip_code || ""}
            />
          </Box>
        </Box>

        <Divider style={{ margin: "15px 0" }} />
        <Box display="flex">
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Make
                </Typography>
                <FormControl sx={{ minWidth: 200 }} fullWidth>
                  <Select
                    labelId="make"
                    id="make"
                    {...register("make")}
                    defaultValue={selected.make || ""}
                    onChange={(event) => {
                      setValue("make", event.target.value);
                      handleMakeChange(event);
                    }}
                  >
                    {inventoryMakes.map((make) => (
                      <MenuItem key={make.id} value={make.make}>
                        {make.make}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Model
                </Typography>
                <FormControl sx={{ minWidth: 200 }} fullWidth>
                  <Select
                    labelId="model"
                    id="model"
                    defaultValue={selected.model || ""}
                    {...register("model")}
                    onChange={(event) => {
                      setValue("model", event.target.value);
                      fetchDealer();
                    }}
                  >
                    {availableModels.map((model) => (
                      <MenuItem key={model} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Year
                </Typography>
                <TextField
                  {...register("year")}
                  defaultValue={selected.year || ""}
                  onChange={(event) => {
                    setValue("year", event.target.value);
                    if (event.target.value.length === 4) {
                      fetchDealer();
                    }
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Dealer ID
                </Typography>
                <TextField
                  {...register("dealer_id")}
                  defaultValue={selected.dealer_id || ""}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="subtitle1" gutterBottom>
                  Auto loan offers?
                </Typography>
                <Checkbox
                  {...register("auto_loan_offers")}
                  defaultChecked={selected.auto_loan_offers || false}
                />
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="subtitle1" gutterBottom>
                  Insurance offers?
                </Typography>
                <Checkbox
                  {...register("insurance_offers")}
                  defaultChecked={selected.insurance_offers || false}
                />
              </Box>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="subtitle1" gutterBottom>
                  Vehicle is used?
                </Typography>
                <Checkbox
                  {...register("isUsed")}
                  defaultChecked={selected.isUsed || false}
                  onChange={(event) => {
                    setValue("isUsed", event.target.checked);
                    fetchDealer();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={!avaliableSubmit}
          type="button"
          onClick={() => handleSubmitAutoWeb()}
        >
          submit autoweb
        </Button>
        <Button variant="outlined" type="submit" autoFocus>
          Save
        </Button>
        <Button variant="outlined" onClick={() => setOpenMore(false)}>
          Close
        </Button>
      </DialogActions>
    </form>
  );
};

export default EditComponent;
