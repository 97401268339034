import React, { createContext, useState } from 'react'

const DateFilterContext = createContext()

export function DateFilterProvider({ children }) {
  const [values, setValues] = useState([])

  return (
    <DateFilterContext.Provider value={[values, setValues]}>
      {children}
    </DateFilterContext.Provider>
  )
}

export function useDateFilter() {
  return React.useContext(DateFilterContext)
}
