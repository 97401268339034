import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";

import InventoryIcon from "@mui/icons-material/Inventory";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { firebaseConfig as config } from "./FIREBASE_CONFIG";
import SearchIcon from "@mui/icons-material/Search";
import ArticleIcon from "@mui/icons-material/Article";
import { DateFilterProvider } from "./contextts/DateFilterContext";
import { Divistocks } from "./pages/Divistocks";
import {BlogLeadForm, HomeLeadForm, InventoryLeadForm, SearchLeadForm} from "./pages/LeadForm2";
import {
  ClickDealerInventoryLeadForm,
  ClickInventoryBlogLeadForm,
  ClickInventorySearchLeadForm,
  InventoryStartLeadForm
} from "./pages/LeadForm1";
import Dashboard from "./pages/dashboard";

const options: RAFirebaseOptions = {
  logging: true,
  rootRef: "root_collection/some_document",
  watch: ["lead_users"],
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const App = () => {
  return (
    <DateFilterProvider>
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource
            name="dashboard"
            options={{ label: "Dashboard" }}
            list={Dashboard}
        />

        <Resource
            name="home"
            options={{ label: "Home Page" }}
            list={HomeLeadForm}
        />

        <Resource
            name="search"
            options={{ label: "Search Page" }}
            list={SearchLeadForm}/>

        <Resource
            name="blog"
            options={{ label: "Blog Page" }}
            list={BlogLeadForm}/>

        <Resource
            name="inventory"
            options={{ label: "Inventory Page" }}
            list={InventoryLeadForm}/>

        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="click_view_inventory_search"*/}
        {/*    options={{ label: "click_view_inventory_search" }}*/}
        {/*    list={ClickInventorySearchLeadForm}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="click_view_inventory_blog"*/}
        {/*    options={{ label: "click_view_inventory_blog" }}*/}
        {/*    list={ClickInventoryBlogLeadForm}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="click_find_local_dealer"*/}
        {/*    options={{ label: "click_find_local_dealer" }}*/}
        {/*    list={ClickDealerInventoryLeadForm}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="lead_form_start_inventory"*/}
        {/*    options={{ label: "lead_form_start_inventory" }}*/}
        {/*    list={InventoryStartLeadForm}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="lead_form_submit_home"*/}
        {/*    options={{ label: "lead_form_submit_home" }}*/}
        {/*    list={HomeLeadForm}*/}
        {/*    icon={LeaderboardIcon}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*  noLa*/}
        {/*  name="lead_form_submit_search"*/}
        {/*  options={{ label: "lead_form_submit_search" }}*/}
        {/*  list={SearchLeadForm}*/}
        {/*  icon={SearchIcon}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="lead_form_submit_inventory"*/}
        {/*    options={{ label: "lead_form_submit_inventory" }}*/}
        {/*    list={InventoryLeadForm}*/}
        {/*    icon={InventoryIcon}*/}
        {/*/>*/}
        {/*<Resource*/}
        {/*    noLa*/}
        {/*    name="lead_form_submit_blog"*/}
        {/*    options={{ label: "lead_form_submit_blog" }}*/}
        {/*    list={BlogLeadForm}*/}
        {/*    icon={ArticleIcon}*/}
        {/*/>*/}
        <Resource
          noLa
          name="divistocks"
          options={{ label: "divistocks" }}
          list={Divistocks}
          icon={OpenInNewIcon}
        />
      </Admin>
    </DateFilterProvider>
  );
};

export default App;
