import React, { useEffect, useState } from "react";
import FirebaseService from "../services/FirebaseService";
import {Box, LinearProgress, Typography} from "@mui/material";

const card = (title?: string, value?: number, bgColor: string = "#ffff") => (
    <Box
        display="flex"
        flexDirection="column"
        width={300}
        height={200}
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: bgColor, borderRadius: "4px" }}
    >
        <Typography variant="h2">{value}</Typography>
        <Typography variant="h6">{title}</Typography>
    </Box>
);

const Dashboard: React.FC = () => {
    const [events, setEvents] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        FirebaseService.countEvents({}).then(result => {
            setEvents(result);
            setLoading(false);
        });
    }, []);

    return (
        <>
            {loading && <LinearProgress sx={{ height: "8px" }} />}
            <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', flexWrap: 'wrap'}}>
                {events.map((row: any) => (
                    card(row.event, row.total, "rgb(209, 233, 252)")
                ))}
            </Box>
        </>
    );
}

export default Dashboard;
