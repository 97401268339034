import React, { useEffect, useState } from "react";
import FirebaseService from "../services/FirebaseService";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";
import { converterDate } from "../utils/DateUtil";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import BasicDateRangePicker from "../components/BasicDateRangePicker";
import { useDateFilter } from "../../contextts/DateFilterContext";
import EditComponent from "./EditForm";
import { Edit } from "@mui/icons-material";

interface DateFilter {
  startDate: number | null;
  endDate: number | null;
}


const events:any = {
  "inventory": {"name": "click_find_local_dealer", "label": 'Find local dealer click'},
  "search": {"name": "click_view_inventory_search", "label": "View Inventory Click"},
  "blog": {"name": "click_view_inventory_blog", "label": "View Inventory Blog Click"}
};

const LeadForm2: React.FC<{origin: string}> = ({origin}) => {
  const [leadUsers, setLeadUsers] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [page, setPage] = useState(0);
  const [loading, setLoadig] = useState(false);
  const [filter, setFilter] = useState("all");
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [allSeleted, setAllSeletet] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>("");
  const [leads, setLeads] = useState<any[]>([]);
  const [values] = useDateFilter();
  const [totalLead, setTotalLead] = useState<number>(0);
  const [totalLeadSuccess, setTotalLeadSuccess] = useState<number>(0);
  const [totalLeadFail, setTotalFail] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dynamicCards, setDynamicCards] = useState<any>({});

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: any) => {
    setFilter(event.target.value);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const removeCommas = (address: string) => {
    return address.replace(/,/g, "");
  };

  const handleSelect = (item: any, edit: boolean) => {
    setSelected(item);
    setOpenMore(true);
    setIsEdit(edit);
  };

  const getDateFilter = (): DateFilter => {
    const date: DateFilter = { startDate: null, endDate: null };

    if (values.length > 1) {
      const startDate = new Date(values[0]);
      startDate.setHours(0, 0, 0, 0);
      date.startDate = startDate.getTime();

      const endDate = new Date(values[1]);
      endDate.setHours(23, 59, 59, 999);
      date.endDate = endDate.getTime();
    }

    return date;
  };

  function generateCSV() {
    const items = leads.length > 0 ? leads : leadUsers;
    const csvHeader =
      "Name,Email,Phone,Date,Type,Address,City,State,ZipCode, Make,Model,Year,Auto loan offers,Insurance offers,Error\n";
    const csvRows = items
      .map(
        (element) =>
          `${
            element.full_name || `${element.first_name} ${element.last_name}`
          },${element.email},${element.phone},${converterDate(
            new Date(element.timestamp)
          )},${element.type},${
            (element.address && removeCommas(element.address)) || ""
          },${element.city || ""},${element.state || ""},${
            element.zip_code || ""
          },${element.make},${element.model},${element.year}, ${
            element.auto_loan_offers ? "Yes" : "No"
          },${element.insurance_offers ? "Yes" : "No"},${element.error || "No"}`
      )
      .join("\n");
    return `${csvHeader}${csvRows}`;
  }

  function downloadCSV() {
    const blob = new Blob([generateCSV()], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Lead-form-1.csv");
    link.click();
  }

  const fetchData = async () => {
    setLoadig(true);
    const dateFilter: DateFilter = getDateFilter();

    try {
      const params = {
        origin,
        leadType: "form_submit",
        type: filter,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        addType: true,
      };
      const data = await FirebaseService.findAllLeadForm(params);
      setLeadUsers(data);

      fetchCountData(params);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadig(false);
    }
  };

  const fetchCountData = async (filter: any) => {
    const params = {...filter};
    const countLeadForm = await FirebaseService.findLeadFormCountAll(params);
    setTotalLead(countLeadForm);

    const countSuccess = await FirebaseService.findLeadForm2CountAllSuccess(params);
    setTotalLeadSuccess(countSuccess);

    const countFail = await FirebaseService.findLeadForm2CountAllFail(params);
    setTotalFail(countFail);

    params.leadType = "form_start";
    params.addType = false;
    const countLeadFormStart = await FirebaseService.findLeadFormCountAll(params);

    params.leadType = "form_submit";
    const countLeadFormFinish = await FirebaseService.findLeadFormCountAll(params);

    const event = events[origin];

    if(event) {
      params.leadType = event.name;
      const countCustomEvent = await FirebaseService.findLeadFormCountAll(params);

      setDynamicCards({
        form_start: countLeadFormStart,
        form_submit: countLeadFormFinish,
        custom_total: countCustomEvent,
        custom_name: event.label
      });
    } else {
      setDynamicCards({
        form_start: countLeadFormStart,
        form_submit: countLeadFormFinish,
      });
    }
  };

  useEffect(() => {
    if(values.length > 0) {
      fetchData();
    }
  }, [filter, values]);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedUsers = leadUsers.slice(startIndex, endIndex);

  const handleCheckItem = (item: any) => {
    const index = leads.findIndex((it) => it.id === item.id);
    if (index !== -1) {
      const updatedList = [...leads];
      updatedList.splice(index, 1);
      setLeads(updatedList);
    } else {
      setLeads([...leads, item]);
    }
  };

  const isSeleted = (item: any): boolean => {
    return leads.some((it) => it.id === item.id);
  };

  const handleCheckAll = () => {
    setAllSeletet(!allSeleted);
    if (!allSeleted) {
      setLeads([...paginatedUsers]);
    } else {
      setLeads([]);
    }
  };

  const card = (title?: string, value?: number, bgColor: string = "#ffff") => (
    <Box
      display="flex"
      flexDirection="column"
      width={300}
      height={200}
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: bgColor, borderRadius: "4px" }}
    >
      <Typography variant="h2">{value}</Typography>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );

  const renderDetail = () => {
    return (
      <>
        <DialogContent>
          <Box
            sx={{ marginBottom: 5 }}
            display="flex"
            justifyContent="flex-end"
          >
            {selected.type === "success" ? (
              <CheckCircleIcon color="success" />
            ) : (
              <ErrorIcon color="error" />
            )}
          </Box>
          <Box display="flex" gap={2}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Name
              </Typography>
              <Typography style={{ width: 300 }} variant="h6" gutterBottom>
                {selected.full_name ||
                  `${selected.first_name} ${selected.last_name}`}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Email
              </Typography>
              <Typography style={{ width: 400 }} variant="h6">
                {selected.email}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Phone
              </Typography>
              <Typography style={{ width: 150 }} variant="h6">
                {selected.phone}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Date Time
              </Typography>
              <Typography style={{ width: 200 }} variant="h6">
                {selected.timestamp
                  ? converterDate(new Date(selected.timestamp))
                  : ""}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ margin: "15px 0" }} />
          <Box display="flex" gap={10}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Address
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.address}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                City
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.city || ""}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                State
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.state || ""}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                ZipCode
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.zip_code || ""}
              </Typography>
            </Box>
          </Box>

          <Divider style={{ margin: "15px 0" }} />
          <Box display="flex" gap={10}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Make
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.make || ""}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Model
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.model || ""}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Year
              </Typography>
              <Typography variant="h6">{selected.year || ""}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Auto loan offers
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.auto_loan_offers ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Insurance offers:
              </Typography>
              <Typography variant="h6" gutterBottom>
                {selected.insurance_offers ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Vehicle condition:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {selected.isUsed ? "used" : "new"}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" gutterBottom>
                Type:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {selected.type}
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Typography variant="h6" sx={{ color: "red" }} gutterBottom>
              {selected.error || ""}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMore(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      {loading && <LinearProgress sx={{ height: "8px" }} />}
      <Typography variant="h4" marginTop={2} gutterBottom>Events</Typography>
      <Box gap={2} display="flex" marginBottom={2}>
        {card("Form Start", dynamicCards.form_start || 0, "rgb(209, 233, 252)")}
        {card("Form Finish", dynamicCards.form_submit || 0, "rgb(208, 242, 255)")}
        {events[origin] && card(dynamicCards.custom_name || "", dynamicCards.custom_total || 0, "rgb(208, 242, 255)")}
      </Box>

      <Typography variant="h4" marginTop={2} gutterBottom>Form Submissions</Typography>
      <Box gap={2} display="flex" marginBottom={2}>
        {card("Total", totalLead, "rgb(209, 233, 252)")}
        {card("Send Success", totalLeadSuccess, "rgb(208, 242, 255)")}
        {card("Send failed", totalLeadFail, "rgb(255, 231, 217)")}
        {/* {card("Test case", totalTestCase, "rgb(230, 247, 211)")} */}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems={"center"}>
        <Box display="flex" sx={{ gap: "5px" }} alignItems="center">
          <Typography variant="body1">{`Total search results: ${leadUsers.length}`}</Typography>
          {(filter === "success" || filter === "fail") && (
            <Typography
              variant="caption"
              sx={{
                border: "1px solid #2196f3",
                borderRadius: "5px",
                padding: "4px",
                color: "#2196f3",
              }}
            >
              This filter does not consider test cases
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <BasicDateRangePicker height={40}/>
          <FormControl
            sx={{ display: "flex", m: 1, minWidth: 120 }}
            size="small"
          >
            <InputLabel id="demo-select-small-label">Filter</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={filter}
              label="Popup"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"success"}>Send Success</MenuItem>
              <MenuItem value={"fail"}>Send failed</MenuItem>
              <MenuItem value={"test"}>Test cases</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={downloadCSV}
            disabled={leads.length === 0}
          >
            Export CSV
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableCell align="left">
              <Checkbox onClick={() => handleCheckAll()} />
            </TableCell>
            <TableCell style={{ width: 300 }}>Name</TableCell>
            <TableCell style={{ width: 400 }}>Email</TableCell>
            <TableCell style={{ width: 140 }}>Phone</TableCell>
            <TableCell style={{ width: 300 }}>Date</TableCell>
            <TableCell style={{ width: 150 }}>Type</TableCell>
            <TableCell align="center" style={{ width: 150 }}>
              Actions
            </TableCell>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <Checkbox
                    checked={isSeleted(row)}
                    onClick={() => handleCheckItem(row)}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.full_name || `${row.first_name} ${row.last_name}`}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">
                  {row.timestamp ? converterDate(new Date(row.timestamp)) : ""}
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1" gutterBottom>
                    {row.type === "success" ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ErrorIcon color="error" />
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleSelect(row, false)}>
                    <MoreVertIcon />
                  </Button>
                  <Button onClick={() => handleSelect(row, true)}>
                    <Edit />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[100, 200]}
          component="div"
          count={leadUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog
        open={openMore}
        onClose={() => setOpenMore(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        {isEdit ? (
          <EditComponent
            selected={selected}
            callbackLoadAll={fetchData}
            setOpenMore={setOpenMore}
          />
        ) : (
          renderDetail()
        )}
      </Dialog>
    </>
  );
}

const HomeLeadForm = (props: any) => <LeadForm2 origin="home" {...props} />
const SearchLeadForm = (props: any) => <LeadForm2 origin="search" {...props} />
const InventoryLeadForm = (props: any) => <LeadForm2 origin="inventory" {...props} />
const BlogLeadForm = (props: any) => <LeadForm2 origin="blog" {...props} />

export {HomeLeadForm, SearchLeadForm, InventoryLeadForm, BlogLeadForm};