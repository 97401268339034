import {
  collection,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  getCountFromServer
} from "firebase/firestore";
import { db } from "../../FIREBASE_CONFIG";

const events:any = [
  {
    leadType: "form_start",
    origin: "home",
  },
  {
    leadType: "form_start",
    origin: "search",
  },
  {
    leadType: "form_submit",
    origin: "home",
  },
  {
    leadType: "form_submit",
    origin: "search",
  },
  {
    leadType: "form_start",
    origin: "inventory",
  },
  {
    leadType: "form_submit",
    origin: "inventory",
  },
  {
    leadType: "form_start",
    origin: "blog",
  },
  {
    leadType: "form_submit",
    origin: "blog",
  },
  {
    leadType: "click_find_local_dealer"
  },
  {
    leadType: "click_view_inventory_search"
  },
  {
    leadType: "click_view_inventory_blog"
  }
];

export default class FirebaseService {

  static countEvents = async (filterParams: {
    startDate?: number | null;
    endDate?: number | null;
  }) => {
    const { startDate, endDate } = filterParams;
    const leadFormCollectionRef = collection(db, "lead_form_2");
    const result = [];

    for (const event of events) {
      let queryRef = query(leadFormCollectionRef, where("lead_type", "==", event.leadType));
      let eventName = event.leadType;

      if(event.origin) {
        eventName = `lead_${event.leadType}_${event.origin}`;
        queryRef = query(queryRef, where("origin", "==", event.origin));
      }

      if (startDate && endDate) {
        queryRef = query(
            queryRef,
            where("timestamp", ">=", startDate),
            where("timestamp", "<=", endDate)
        );
      }
      const querySnapshot = await getCountFromServer(queryRef);
      result.push({
        event: eventName,
        total: querySnapshot.data().count
      });
    }

    return result;
  }

  static findAllLeadForm = async (filterParams: {
    origin: string;
    leadType: string;
    type?: string;
    startDate?: number | null;
    endDate?: number | null;
  }): Promise<any[]> => {
    const { startDate, endDate, type, origin, leadType } = filterParams;

    let leadForm2Query = query(
      collection(db, "lead_form_2"),
      where("origin", "==", origin),
      where("lead_type", "==", leadType),
      orderBy("timestamp", "desc")
    );

    if (type && type !== "all" && type !== "test") {
      leadForm2Query = query(leadForm2Query, where("type", "==", type));
    }

    if (startDate && endDate) {
      leadForm2Query = query(
        leadForm2Query,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(leadForm2Query);
    const leadforms: any[] = [];

    querySnapshot.forEach((doc) => {
      const user = doc.data();
        if (type === "test") {
          if (user.email.includes("divinia") || user.zip_code.includes("99999")) {
            leadforms.push({ ...user, id: doc.id, ref: doc.ref });
          }
        } else {
          if (type === "success" || type === "fail") {
            if (
              !user.email.includes("divinia") &&
              !user.zip_code.includes("99999")
            ) {
              leadforms.push({ ...user, id: doc.id, ref: doc.ref });
            }
          } else {
            leadforms.push({ ...user, id: doc.id, ref: doc.ref });
          }
        }
    });

    return leadforms;
  };

  static findLeadFormCountAll = async (filterParams: {
    origin: string;
    leadType: string;
    addType?: boolean;
    startDate?: number | null;
    endDate?: number | null;
  }): Promise<number> => {
    const { startDate, endDate, origin, leadType, addType } = filterParams;
    const leadFormCollectionRef = collection(db, "lead_form_2");

    let queryRef = query(
      leadFormCollectionRef,
      where("origin", "==", origin),
      where("lead_type", "==", leadType)
    );

    if(addType){
      queryRef = query(
        queryRef,
        where("type", "in", ["fail", "success"]),
      );
    }

    if (startDate && endDate) {
      queryRef = query(
        queryRef,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getCountFromServer(queryRef);
    return querySnapshot.data().count;
  };

  static findLeadForm2CountAllSuccess = async (filterParams: {
    origin: string;
    leadType: string;
    type?: string;
    startDate?: number | null;
    endDate?: number | null;
  }): Promise<number> => {
    const { startDate, endDate, origin } = filterParams;
    const leadFormCollectionRef = collection(db, "lead_form_2");

    let queryRef = query(
        leadFormCollectionRef,
        where("origin", "==", origin),
        where("type", "==", "success"),
    );

    if (startDate && endDate) {
      queryRef = query(
        queryRef,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getCountFromServer(queryRef);
    return querySnapshot.data().count;
  };

  static findLeadForm2CountAllFail = async (filterParams: {
    origin: string;
    leadType: string;
    type?: string;
    startDate?: number | null;
    endDate?: number | null;
  }): Promise<number> => {
    const { startDate, endDate, origin } = filterParams;
    const leadFormCollectionRef = collection(db, "lead_form_2");

    let queryRef = query(
        leadFormCollectionRef,
        where("origin", "==", origin),
        where("type", "==", "fail")
    );

    if (startDate && endDate) {
      queryRef = query(
        queryRef,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(queryRef);
    return querySnapshot.size;
  };

  static updateLeadForm2 = async (data: any): Promise<any> => {
    const querySnapshot = await getDocs(
      query(collection(db, "lead_form_2"), orderBy("timestamp", "desc"))
    );

    for (const doc of querySnapshot.docs) {
      const currentData = doc.data();
      if (doc.id === data.id) {
        if (data.full_name) {
          data = { ...data, first_name: "", last_name: "" };
        }
        await updateDoc(doc.ref, { ...data });
        return currentData;
      }
    }

    return null;
  };

  static updateAllElement = async (): Promise<any[]> => {
    const querySnapshot = await getDocs(
      query(collection(db, "lead_form_2"), orderBy("timestamp", "desc"))
    );

    const inventories: any[] = [];

    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      inventories.push(data);

      if (data.year === 2023 || 2022 || 2024) {
        await updateDoc(doc.ref, { ...data, isUsed: false });
      }

      if (data.year < 2022) {
        await updateDoc(doc.ref, { ...data, isUsed: true });
      }
    }

    console.log("all processed");

    return inventories;
  };
}
