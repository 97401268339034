import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyDDBOrbQ3AR2qr3PQRzeIq3CrnZbIaZxnQ",
  authDomain: "divicars-e4060.firebaseapp.com",
  projectId: "divicars-e4060",
  storageBucket: "divicars-e4060.appspot.com",
  messagingSenderId: "132556372109",
  appId: "1:132556372109:web:d6e044b0b717348d89cac4"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { firebaseApp, db };

